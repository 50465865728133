const translations = [
  {
    accessToDataIsForbidden: {
      hr: "Pristup podacima nije moguć",
      en: "Access to data is forbidden",
    },
    accountNumber: {
      hr: "Broj računa",
      en: "Account number",
    },
    add: {
      hr: "Dodajte",
      en: "Add",
    },
    address: {
      hr: "Adresa",
      en: "Address",
    },
    additionalDescription: {
      hr: "Dodatni opis",
      en: "Additional description",
    },
    administrator: {
      hr: "Administrator",
      en: "Administrator",
    },
    amount: {
      hr: "Iznos",
      en: "Amount",
    },
    amountAbroad: {
      hr: "Iznos (Inozemstvo)",
      en: "Amount (Abroad)",
    },
    amountCroatia: {
      hr: "Iznos (Hrvatska)",
      en: "Amount (Croatia)",
    },
    ascending: {
      hr: "Uzlazno",
      en: "Ascending",
    },
    areYouSure: {
      hr: "Jeste li sigurni?",
      en: "Are you sure?",
    },
    back: {
      hr: "Povratak",
      en: "Back",
    },
    bank: {
      hr: "Banka",
      en: "Bank",
    },
    billing: {
      hr: "Fakturiranje",
      en: "Invoicing",
    },
    calculation: {
      hr: "Izračun",
      en: "Calculation",
    },
    cash: {
      hr: "Pouzeće",
      en: "Cash",
    },
    card: {
      hr: "Kartica",
      en: "Card",
    },
    cancelled: {
      hr: "Otkazano",
      en: "Cancelled",
    },
    cartAmount: {
      hr: "Iznos košarice",
      en: "Cart amount",
    },
    change: {
      hr: "Promjenite",
      en: "Change",
    },
    choose: {
      hr: "Odaberite",
      en: "Choose",
    },
    changePassword: {
      hr: "Promjena lozinke",
      en: "Change password",
    },
    checkYourCredentials: {
      hr: "Provjerite svoje podatke",
      en: "Check your credentials",
    },
    city: {
      hr: "Grad",
      en: "City",
    },
    client: {
      hr: "Klijent",
      en: "Client",
    },
    code: {
      hr: "Kod",
      en: "Code",
    },
    copiedToClipboard: {
      hr: "Kopirano u međuspremnik",
      en: "Copied to clipboard",
    },
    commission: {
      hr: "Provizija",
      en: "Commission",
    },
    commissionAmount: {
      hr: "Iznos provizije",
      en: "Commission amount",
    },
    confirmed: {
      hr: "Potvrđeno",
      en: "Confirmed",
    },
    contact: {
      hr: "Kontakt",
      en: "Contact",
    },
    contactEmail: {
      hr: "Kontakt e-mail",
      en: "Contact e-mail",
    },
    contactPhone: {
      hr: "Kontakt telefon",
      en: "Contact phone",
    },
    contactPerson: {
      hr: "Kontakt osoba",
      en: "Contact person",
    },
    continue: {
      hr: "Nastavite",
      en: "Continue",
    },
    country: {
      hr: "Država",
      en: "Country",
    },
    county: {
      hr: "Županija",
      en: "County",
    },
    coupon: {
      hr: "Kupon",
      en: "Coupon",
    },
    coupons: {
      hr: "Kuponi",
      en: "Coupons",
    },
    createdAt: {
      hr: "Kreirano",
      en: "Created at",
    },
    currency: {
      hr: "Valuta",
      en: "Currency",
    },
    currencies: {
      hr: "Valute",
      en: "Currencies",
    },
    dashboard: {
      hr: "Radna ploča",
      en: "Dashboard",
    },
    dataNotFound: {
      hr: "Podatak nije pronađen",
      en: "Data not found",
    },
    date: {
      hr: "Datum",
      en: "Date",
    },
    dateTime: {
      hr: "Datum i vrijeme",
      en: "Datetime",
    },
    delete: {
      hr: "Uklanjanje",
      en: "Delete",
    },
    delivery: {
      hr: "Dostava",
      en: "Delivery",
    },
    deliveryAmount: {
      hr: "Iznos dostave",
      en: "Delivery amount",
    },
    deliverer: {
      hr: "Dostavljač",
      en: "Deliverer",
    },
    descending: {
      hr: "Silazno",
      en: "Descending",
    },
    description: {
      hr: "Opis",
      en: "Description",
    },
    descriptionAdditional: {
      hr: "Opis (dodatno)",
      en: "Description (additional)",
    },
    discount: {
      hr: "Popust",
      en: "Discount",
    },
    document: {
      hr: "Dokument",
      en: "Document",
    },
    documents: {
      hr: "Dokumenti",
      en: "Documents",
    },
    dg: {
      hr: "dg",
      en: "dg",
    },
    dl: {
      hr: "dl",
      en: "dl",
    },
    download: {
      hr: "Preuzimanje",
      en: "Download",
    },
    earlyBird: {
      hr: "Early Bird",
      en: "Early Bird",
    },
    emailCannotBeSent: {
      hr: "E-mail se ne može poslati",
      en: "E-amil cannot be sent",
    },
    email: {
      hr: "E-mail",
      en: "E-mail",
    },
    expiredAt: {
      hr: "Ističe",
      en: "Expired at",
    },
    filter: {
      hr: "Filter",
      en: "Filter",
    },
    forgottenPassword: {
      hr: "Zaboravljena lozinka?",
      en: "Forgotten password?",
    },
    g: {
      hr: "g",
      en: "g",
    },
    general: {
      hr: "Općenito",
      en: "General",
    },
    getRecoveryToken: {
      hr: "Ishodi token za oporavak",
      en: "Get recovery token",
    },
    hello: {
      hr: "Bok",
      en: "Hello",
    },
    id: {
      hr: "ID",
      en: "ID",
    },
    income: {
      hr: "Prihod",
      en: "Income",
    },
    information: {
      hr: "Informacije",
      en: "Information",
    },
    internetBanking: {
      hr: "Internet bankarstvo",
      en: "Internet banking",
    },
    isActive: {
      hr: "Aktivno",
      en: "Active",
    },
    isCashOnDeliveryEnabled: {
      hr: "Pouzeće",
      en: "Cash on delivery",
    },
    isMain: {
      hr: "Glavna",
      en: "Main",
    },
    isOffline: {
      hr: "Nedostupno",
      en: "Offline",
    },
    isPublic: {
      hr: "Javno",
      en: "Public",
    },
    isInVATSystem: {
      hr: "U sustavu PDV-a",
      en: "In VAT system",
    },
    isVerified: {
      hr: "Verificirano",
      en: "Verified",
    },
    kg: {
      hr: "kg",
      en: "kg",
    },
    l: {
      hr: "l",
      en: "l",
    },
    latitude: {
      hr: "Geografska širina",
      en: "Latitude",
    },
    longitude: {
      hr: "Geografska dužina",
      en: "Longitude",
    },
    login: {
      hr: "Prijava",
      en: "Login",
    },
    logout: {
      hr: "Odjava",
      en: "Logout",
    },
    locations: {
      hr: "Lokacije",
      en: "Locations",
    },
    marginAbroad: {
      hr: "Margina (Inozemstvo)",
      en: "Margin (Abroad)",
    },
    marginCroatia: {
      hr: "Margina (Hrvatska)",
      en: "Margin (Croatia)",
    },
    menu: {
      hr: "Izbornik",
      en: "Menu",
    },
    ml: {
      hr: "ml",
      en: "ml",
    },
    modelNotFound: {
      hr: "Model nije pronađen",
      en: "Model not found",
    },
    name: {
      hr: "Naziv",
      en: "Name",
    },
    no: {
      hr: "Ne",
      en: "No",
    },
    noDataYet: {
      hr: "Trenutno nema podataka...",
      en: "No data yet...",
    },
    note: {
      hr: "Napomena",
      en: "Note",
    },
    role: {
      hr: "Uloga",
      en: "Role",
    },
    order: {
      hr: "Narudžba",
      en: "Order",
    },
    orders: {
      hr: "Narudžbe",
      en: "Orders",
    },
    orderProducts: {
      hr: "Naručeni proizvodi",
      en: "Order products",
    },
    orderBy: {
      hr: "Sortiraj prema",
      en: "Order by",
    },
    orderType: {
      hr: "Sortiraj redom",
      en: "Order type",
    },
    owner: {
      hr: "Vlasnik",
      en: "Owner",
    },
    owners: {
      hr: "Vlasnici",
      en: "Owners",
    },
    paginate: {
      hr: "Paginacija",
      en: "Paginate",
    },
    passwordHasBeenChanged: {
      hr: "Lozinka je promjenjena",
      en: "Password has been changed",
    },
    passwordIsMissing: {
      hr: "Nedostaje lozinka",
      en: "Password is missing",
    },
    passwordRecovery: {
      hr: "Oporavak lozinke",
      en: "Password recovery",
    },
    passwordRecoveryEmailHasBeenSent: {
      hr: "E-mail za oporavak lozinke je poslan",
      en: "Password recovery email has been sent",
    },
    paymentType: {
      hr: "Vrsta plaćanja",
      en: "Payment Type",
    },
    paymentTypes: {
      hr: "Vrste plaćanja",
      en: "Payment Types",
    },
    phone: {
      hr: "Telefon",
      en: "Phone",
    },
    photo: {
      hr: "Fotografija",
      en: "Photo",
    },
    photos: {
      hr: "Fotografije",
      en: "Photos",
    },
    plan: {
      hr: "Plan",
      en: "Plan",
    },
    product: {
      hr: "Proizvod",
      en: "Product",
    },
    products: {
      hr: "Proizvodi",
      en: "Products",
    },
    province: {
      hr: "Županija",
      en: "Province",
    },
    password: {
      hr: "Lozinka",
      en: "Password",
    },
    pending: {
      hr: "Na čekanju",
      en: "Pending",
    },
    piece: {
      hr: "komad",
      en: "piece",
    },
    pleaseLoginAgain: {
      hr: "Molimo prijavite se ponovno",
      en: "Please login again",
    },
    pleaseVerifyYourAccount: {
      hr: "Molimo verificirajte svoj račun",
      en: "Please verify your account",
    },
    pleaseUploadPhoto: {
      hr: "Molimo prenesite fotografiju",
      en: "Please upload photo",
    },
    postalCode: {
      hr: "Poštanski broj",
      en: "Postal code",
    },
    price: {
      hr: "Cijena",
      en: "Price",
    },
    private: {
      hr: "Privatno",
      en: "Private",
    },
    productCategory: {
      hr: "Kategorija proizvoda",
      en: "Product Category",
    },
    productCategories: {
      hr: "Kategorije proizvoda",
      en: "Product Categories",
    },
    public: {
      hr: "Javno",
      en: "Public",
    },
    purchaseCode: {
      hr: "Kod za kupnju",
      en: "Purchase code",
    },
    recipient: {
      hr: "Primatelj",
      en: "Recipient",
    },
    search: {
      hr: "Pretraga",
      en: "Search",
    },
    send: {
      hr: "Pošalji",
      en: "Send",
    },
    seeYouSoon: {
      hr: "Vidimo se uskoro",
      en: "See you soon",
    },
    settings: {
      hr: "Postavke",
      en: "Settings",
    },
    shop: {
      hr: "Trgovina",
      en: "Shop",
    },
    shops: {
      hr: "Trgovine",
      en: "Shops",
    },
    sku: {
      hr: "SKU",
      en: "SKU",
    },
    specification: {
      hr: "Specifikacija",
      en: "Specification",
    },
    somethingWentWrong: {
      hr: "Nešto je pošlo po krivu",
      en: "Something went wrong",
    },
    standard: {
      hr: "Standard",
      en: "Standard",
    },
    statistics: {
      hr: "Statistika",
      en: "Statistics",
    },
    status: {
      hr: "Status",
      en: "Status",
    },
    stock: {
      hr: "Zaliha",
      en: "Stock",
    },
    subscription: {
      hr: "Pretplata",
      en: "Subscription",
    },
    subscriptions: {
      hr: "Pretplate",
      en: "Subscriptions",
    },
    successfullyDeleted: {
      hr: "Uspješno obrisano",
      en: "Successfully deleted",
    },
    successfullySaved: {
      hr: "Uspješno spremljeno",
      en: "Successfully saved",
    },
    superAdministrator: {
      hr: "Super Administrator",
      en: "Super Administrator",
    },
    title: {
      hr: "Naziv",
      en: "Title",
    },
    token: {
      hr: "Token",
      en: "Token",
    },
    tokenIsValid: {
      hr: "Token je ispravan",
      en: "Token is valid",
    },
    tokenHasExpired: {
      hr: "Token je istekao",
      en: "Token has expired",
    },
    total: {
      hr: "Ukupno",
      en: "Total",
    },
    trackingCode: {
      hr: "Kod za praćenje",
      en: "Tracking code",
    },
    typeYourNewPassword: {
      hr: "Unesite novu lozinku",
      en: "Type your new password",
    },
    unit: {
      hr: "Jedinica",
      en: "Unit",
    },
    unitAmount: {
      hr: "Jedinična količina",
      en: "Unit amount",
    },
    unknown: {
      hr: "Nepoznato",
      en: "Unknown",
    },
    update: {
      hr: "Ažuriraj",
      en: "Update",
    },
    updateTrackingCode: {
      hr: "Ažuriraj kod za praćenje",
      en: "Update tracking code",
    },
    upload: {
      hr: "Prijenos",
      en: "Upload",
    },
    users: {
      hr: "Korisnici",
      en: "Users",
    },
    user: {
      hr: "Korisnik",
      en: "User",
    },
    userAlreadyExists: {
      hr: "Korisnik već postoji",
      en: "User already exists",
    },
    userNotFound: {
      hr: "Korisnik nije pronađen",
      en: "User not found",
    },
    url: {
      hr: "URL",
      en: "URL",
    },
    vat: {
      hr: "PDV",
      en: "VAT",
    },
    vatAmount: {
      hr: "Iznos PDV-a",
      en: "VAT amount",
    },
    vatNumber: {
      hr: "OIB",
      en: "VAT number",
    },
    verification: {
      hr: "Verifikacija",
      en: "Verification",
    },
    views: {
      hr: "Pregledi",
      en: "Views",
    },
    yes: {
      hr: "Da",
      en: "Yes",
    },
    yesIAm: {
      hr: "Da, jesam",
      en: "Yes, I am",
    },
    youAreLoggedOut: {
      hr: "Odjavljeni ste",
      en: "You are logged out",
    },
    yourAccountHasBeenVerified: {
      hr: "Vaš račun je verificiran",
      en: "Your account has been verified",
    },
    waiting: {
      hr: "Čekanje",
      en: "Waiting",
    },
    welcomeBack: {
      hr: "Dobro došli nazad",
      en: "Welcome back",
    },
    welcomeToOurPlatform: {
      hr: "Dobro došli na našu platformu",
      en: "Welcome to our platform",
    },
    quantity: {
      hr: "Količina",
      en: "Quantity",
    },
  },
];
export default translations;
